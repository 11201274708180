import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Global CSS.  Any css you load in a React App is available anywhere in the app, no matter what component you import it in.
import "./index.css";

// ROUTE IMPORTS
import Home from "./routes/Home";
import Greenroom from "./routes/Greenroom";
import Manage from "./routes/Manage";

// SOCKET CONTEXT
import { SocketContext, socket } from "./context/socket";

ReactDOM.render(
  <>
    <SocketContext.Provider value={socket}>
      <Router>
        <Routes>
          <Route path="/manage" element={<Manage />} />
          <Route path="/manage/:showid" element={<Manage />} />
          <Route path="/greenroom" element={<Greenroom />} />
          <Route path="/" element={<Home />} />
          <Route path="/call/:callid" element={<Home />} />
          <Route path="/show/:showid/call/:callid?" element={<Home />} />
        </Routes>
      </Router>
    </SocketContext.Provider>
  </>,
  document.getElementById("root")
);
