import React from "react";
import { useNavigate } from "react-router-dom";

// THEME PROVIDER
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// MUI COMPONENTS
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// CUSTOM CONTENT LOAD COMPONENT TO DO ANIMATION ONCE CHILD LOADS
import LoadingContentWrapper from "../components/LoadingContentWrapper";

// THEME
const globalTheme = createTheme({
   palette: {
      mode: "dark",
      primary: {
         main: "#3a82e4",
      },
      secondary: {
         main: "#40b2a4",
      },
      info: {
         main: "#aaa7a0",
      },
      warning: {
         main: "#eeca4a",
      },
      success: {
         main: "#085856",
      },
      error: {
         main: "#d25238",
      },
      background: {
         default: "#13181e",
      },
      text: {
         primary: "#ccc",
      },
   },
});

// Note the maxWidth and children props.  maxWidth is an optional parameter in the <Wrapper> component in the parent, children is what is inside the <Wrapper></Wrapper>

function Wrapper({ maxWidth, children }) {
   const navigate = useNavigate();

   return (
      <ThemeProvider theme={globalTheme}>
         <CssBaseline />

         <Box sx={{ p: 4, maxWidth: maxWidth, margin: "0 auto" }}>

            <LoadingContentWrapper isLoaded={true}>
               <Grid
                  container
                  justifyContent='center'
                  alignItems='stretch'
                  columnSpacing={2}
               >
                  <Grid item xs={10}>
                     <Typography variant='h6' component='div'>
                        Call Manager
                     </Typography>
                  </Grid>

                  <Grid item xs={2} sx={{ mb: 1.5, textAlign: "right" }}>
                     <Stack direction='row' spacing={2}>
                        <Button
                           variant='outlined'
                           color='warning'
                           onClick={() => navigate("/")}
                        >
                           HOME
                        </Button>
                        <Button
                           variant='outlined'
                           color='warning'
                           onClick={() => navigate("/Manage")}
                        >
                           Manage
                        </Button>
                     </Stack>
                  </Grid>

                  <Grid item xs={12}>
                     {/* `{children}` is what has been wrapped by <Wrapper></Wrapper> in the parent component */}
                     {children}
                  </Grid>
               </Grid>
            </LoadingContentWrapper>
         </Box>
      </ThemeProvider>
   );
}

export default Wrapper;
