import React, { useState, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";
import { useNavigate } from "react-router-dom";


// THEME PROVIDER
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";


import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from '@mui/material/FormGroup';

import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';


// THEME
const globalTheme = createTheme({
	palette: {
		 mode: "dark",
		 primary: {
				main: "#3a82e4",
		 },
		 secondary: {
				main: "#40b2a4",
		 },
		 info: {
				main: "#aaa7a0",
		 },
		 warning: {
				main: "#eeca4a",
		 },
		 success: {
				main: "#085856",
		 },
		 error: {
				main: "#d25238",
		 },
		 background: {
				default: "#13181e",
		 },
		 text: {
				primary: "#ccc",
		 },
	},
});



export default function Home() {

	// States.  You can have as many as you want.  This is one that receives the sample DB data array.
	const [caller, setCallerData] = useState([]);
	const [frame, setFrame] = useState([]);

	// EFFECTS.  The magic.  This one denoted by [] runs after page load.  Think of it as your page init function.
	useEffect(() => {

	}, []);

	// This effect runs every time the `sampleData` state const is updated.  This is how you run functions after something happens on the page, during the React re-render cycle.  Note the useEffect function ends with [sampleData] to denote what const will fire this effect.
	// useEffect(() => {
	// 	console.log(sampleData);
	// }, [sampleData]);

	// HANDLERS
	// This handler will emit on the socket to create a new entry in the server backend DB.


	return (
		<ThemeProvider theme={globalTheme}>
			<CssBaseline />
				<Typography variant='h2' component='h2'>
					Welcome! {caller.name}
				</Typography>

				<Typography variant='p' component='p'>
					Lets make sure everything is ready to go!
				</Typography>

				<FormGroup>
					<FormControlLabel control={<Checkbox />} label="Wired Network" />
				</FormGroup>
		</ThemeProvider>
	);
}
