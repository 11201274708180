import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";

import { Refresh } from "@mui/icons-material";

import _ from "lodash";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.5s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2)",
      opacity: 0,
    },
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CallerCard({
  row,
  calls,
  callRefresh,
  callAssign,
  i,
  connections,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 345, minWidth: 240 }}>
      <CardHeader
        avatar={
          <Stack direction="row" spacing={2}>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              disconnected="true"
              badgeContent=" "
              color={row.status === "connected" ? "success" : "error"}
            >
              <Avatar
                variant="square"
                alt={_.get(row, "geodata.country")}
                src={_.get(row, "geodata.flag.img")}
              />
            </StyledBadge>
          </Stack>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={row.name}
        subheader={row.social}
      />
      <CardContent></CardContent>
      <CardActions disableSpacing>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Call</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="foo"
            value={connections[i].call}
            label="Call"
            onChange={callAssign}
          >
            {connections[i].call}
            <MenuItem value="Green Room" data-callerid={row.id}>
              Green Room
            </MenuItem>
            {
              //todo filter out existing assigned calls
              calls.map((call, i) => (
                <MenuItem value={call.id} data-callerid={row.id}>
                  {call.name}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <IconButton aria-label="refresh" onClick={callRefresh}>
          <Refresh data-callerid={row.id} />
        </IconButton>

        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {_.get(row, "geodata.city")}, {_.get(row, "geodata.region")}
            <br />
            {_.get(row, "geodata.country")} <br />
            {_.get(row, "browser.browser")} <br /> {_.get(row, "browser.os")}{" "}
            <br />
            {_.get(row, "geodata.connection.domain")} (
            {_.get(row, "geodata.connection.isp")})
          </Typography>
          Socket ID: {row.id} <br />
          Session ID: {row.sessionid}
        </CardContent>
      </Collapse>
    </Card>
  );
}
