import React from "react";
import { io } from "socket.io-client";

const SERVER_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:8888";

console.log("Socket Server is ", SERVER_URL);

export const socket = io.connect(SERVER_URL);

export const SocketContext = React.createContext(socket);
