import React, { useState, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";
import { useNavigate } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Caller from "../components/Caller";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import _ from "lodash";

export default function Manage() {
  const navigate = useNavigate();
  const socket = useContext(SocketContext);

  // STATES
  const [sampleData, setSampleData] = useState([]);
  const [connections, setConnectionData] = useState([]);
  const [calls, setCallsData] = useState([]);
  const [call, setCallData] = useState([]);

  socket.on("connections", (result) => {
    console.log("new connection data");
    setConnectionData(result);
  });

  // EFFECTS
  useEffect(() => {
    socket.emit("findAll", (result) => {
      if (result) {
        setSampleData(result);
      }
    });

    socket.emit("connections", (result) => {
      console.log(result);
      if (result) {
        setConnectionData(result);
      }
    });

    socket.emit("calls", (result) => {
      console.log(result);
      if (result) {
        setCallsData(result);
      }
    });

    socket.emit("browser", (result) => {
      console.log("Browser stats");
      console.log(result);
    });
  }, []);

  useEffect(() => {
    console.log(sampleData);
  }, [sampleData]);

  // const addEntry = () => {
  // 	socket.emit("bulkCreateUpdate", [{ name: "Sample Entry" }], (result) => {
  // 		if (result) {
  // 			setSampleData(result);
  // 		}
  // 	});
  // };

  // const deleteEntry = (e) => {
  // 	const id = e.target.id;

  // 	socket.emit("deleteOne", id, (result) => {
  // 		if (result) {
  // 			setSampleData(result);
  // 		}
  // 	});
  // };

  function handleCallNameChange(e) {
    setCallData({
      ...call,
      name: e.target.value,
    });
  }

  function handleCallURLChange(e) {
    setCallData({
      ...call,
      url: e.target.value,
    });
  }

  const callCreate = () => {
    console.log("save the call");
    console.log(call);

    socket.emit("call create", call, (result) => {
      if (result) {
        setCallsData(result);
      }
    });
  };

  const callDelete = (e) => {
    console.log("delete call");
    console.log(e.target.id);

    socket.emit("call delete", { id: e.target.id }, (result) => {
      if (result) {
        setCallsData(result);
      }
    });
  };

  const callAssign = (e, select) => {
    console.log("assign call");
    console.log(e);
    console.log(select.props);

    socket.emit(
      "call assign",
      {
        callerid: select.props["data-callerid"],
        callid: select.props.value,
      },
      (result) => {
        if (result) {
          console.log(result);
          //setCallsData(result);
        }
      }
    );
  };

  const callRefresh = (e) => {
    console.log("call reload");
    var callerid = _.get(e, "target.attributes['data-callerid'].value");

    console.log(callerid);

    socket.emit(
      "call reload",
      {
        callerid: e.target.attributes["data-callerid"].value,
      },
      (result) => {
        if (result) {
          console.log(result);
          //setCallsData(result);
        }
      }
    );
  };

  //TODO refactor the way we handle these changes

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Wrapper maxWidth={1500}>
      <Typography variant="h2" component="h2">
        Callers
      </Typography>

      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            {connections.map((row, i) => (
              <Grid key={i} item>
                <Caller
                  i={i}
                  row={row}
                  calls={calls}
                  connections={connections}
                  callRefresh={callRefresh}
                  callAssign={callAssign}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Typography variant="h2" component="h2">
        Calls
      </Typography>

      <Box
        component="form"
        sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="call-name"
          label="Call Name"
          variant="filled"
          value={call.name}
          onChange={handleCallNameChange}
        />
        <TextField
          id="call-url"
          label="URL"
          variant="filled"
          value={call.url}
          onChange={handleCallURLChange}
        />
        <Button onClick={callCreate} variant="contained" sx={{ mt: 4 }}>
          Create Call
        </Button>
      </Box>

      <h2>Call Name: {call.name}</h2>
      <h2>Call URL: {call.url}</h2>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="right">Name</StyledTableCell>
              <StyledTableCell align="right">URL</StyledTableCell>
              <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calls.map((row, i) => (
              <StyledTableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.id}
                </StyledTableCell>
                <StyledTableCell align="right">{row.name}</StyledTableCell>
                <StyledTableCell align="right">{row.url}</StyledTableCell>
                <StyledTableCell align="right">
                  <Button
                    id={row.id}
                    onClick={callDelete}
                    color="error"
                    variant="contained"
                  >
                    Delete
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
}
