import React, { useState, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import Wrapper from "../components/Wrapper";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

export default function Home() {
  // You should use `navigate`, not href anywhere in the React application—like on Button or <a>.  `href` will trigger an Express page reload which will create a new socket.
  const navigate = useNavigate();
  let params = useParams();

  // This accesses the socket from the socket.io context
  const socket = useContext(SocketContext);

  // States.  You can have as many as you want.  This is one that receives the sample DB data array.
  const [caller, setCallerData] = useState([]);
  const [frame, setFrame] = useState([]);

  const [cookies, setCookie] = useCookies(["sessionid", "name", "social"]);

  // EFFECTS.  The magic.  This one denoted by [] runs after page load.  Think of it as your page init function.
  useEffect(() => {
    console.log("params", params);

    if (!cookies.sessionid) {
      cookies.sessionid = uuidv4();
      setCookie("sessionid", cookies.sessionid, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365,
      });
    }

    setCallerData({
      ...caller,
      name: cookies.name,
      social: cookies.social,
    });

    // setFrame({ url: 'https://advanced.vmixcall.com/call.htm?Key=1891508067&Name=Caller1', key: 0 });
    // setFrame({ url: 'https://advanced.vmixcall.com/call.htm?Key=7772877161&Name=Caller2', key: 0 });

    setFrame({ url: "/greenroom", key: 0 });

    var callerdata = {
      sessionid: cookies.sessionid,
      name: caller.name || cookies.name,
      social: caller.social || cookies.social,
    };

    console.log("caller register", callerdata);

    socket.emit("caller register", callerdata, (result) => {
      if (result) {
        //TODO check and see if we need to assign to a cal
      }
    });
  }, []);

  // This effect runs every time the `sampleData` state const is updated.  This is how you run functions after something happens on the page, during the React re-render cycle.  Note the useEffect function ends with [sampleData] to denote what const will fire this effect.
  // useEffect(() => {
  // 	console.log(sampleData);
  // }, [sampleData]);

  // HANDLERS

  function handleNameChange(e) {
    setCallerData({
      ...caller,
      name: e.target.value,
    });
  }

  function handleSocialChange(e) {
    setCallerData({
      ...caller,
      social: e.target.value,
    });
  }

  const saveCaller = () => {
    console.log("save the caller");
    console.log(caller);

    if (caller.name) {
      setCookie("name", caller.name, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365,
      });
    }

    if (caller.social) {
      setCookie("social", caller.social, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365,
      });
    }

    socket.emit("save", caller, (result) => {
      if (result) {
        console.log("saved data");
        console.log(result);
        //setConnectionData(result);
      }
    });
  };

  socket.on("call assign", (result) => {
    console.log("we have a new call assignment	");
    console.log(result);

    setFrame({ url: result.url, key: 0 });
    //setConnectionData(result);
  });

  socket.on("call reload", (result) => {
    console.log("call reload");

    setFrame({
      ...frame,
      key: frame.key++, //increment the key to force reload
    });
  });

  return (
    // This is a custom wrapper component I built that includes a loader and page fade transition
    <Wrapper maxWidth={1500}>
      <Box
        component="form"
        sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="caller-name"
          label="Your Name"
          variant="filled"
          value={caller.name}
          onChange={handleNameChange}
        />
        <TextField
          id="caller-social"
          label="@socialmedia"
          variant="filled"
          value={caller.social}
          onChange={handleSocialChange}
        />
        <Button onClick={saveCaller} variant="contained" sx={{ mt: 4 }}>
          Save
        </Button>
      </Box>
      <Box>
        <iframe
          key={frame.key}
          src={frame.url}
          width={1000}
          height={500}
          allow=" camera; microphone; accelerometer; autoplay; clipboard-write; encrypted-media; camera; microphone;"
          frameBorder="0"
        ></iframe>
      </Box>
      <h2>Debug Info</h2>
      Name: {caller.name}
      <br />
      Social: {caller.social}
      <br />
      SessionID: {cookies.sessionid}
      <br />
      Frame URL: {frame.url}
      <br />
      Showid: {params.showid}
      <br />
    </Wrapper>
  );
}
